import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Container, Row, Col } from "reactstrap";
import Img from "gatsby-image";
import RevealBlog from './revealblog';

const BlogPosts = () => {
  const data = useStaticQuery(graphql`
    query BlogQuery {
      wpgraphql {
        page(id: "cGFnZTo5OQ==") {
          title
          acf_page_meta {
            metadescription
          }
          acf_news {
            newsItem {
              date
              headline
              excerpt
              linkUrl
              linkText
              image {
                sourceUrl
                imageFile {
                  childImageSharp {
                    fixed(width: 396, height: 256) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <article>
      {data.wpgraphql.page.acf_news.newsItem.map((items, i) => (
      <RevealBlog items={items}>
        <Container>
          <div className="news__post">
            <Row>
              <Col
                xs={{ size: 12, offset: 1 }}
                sm={{ size: 10, offset: 2 }}
                md={{ size: 6, offset: 1, order: 1 }}
                lg={{ size: 6, offset: 2, order: 1 }}
              >
                <span className="news__post-date">{items.date}</span>
              </Col>
            </Row>
            <Row>
              <Col
                md={{ size: 4, offset: 1, order: 2 }}
                lg={{ size: 3, offset: 1, order: 2 }}
              >
                {items.image != null && (
                  <Img
                    className="w-100 d-none d-md-block"
                    fixed={items.image.imageFile.childImageSharp.fixed}
                  />
                )}
              </Col>
              <Col
                xs={{ size: 12, offset: 1 }}
                sm={{ size: 10, offset: 2 }}
                md={{ size: 6, offset: 1, order: 1 }}
                lg={{ size: 6, offset: 2, order: 1 }}
              >
                <h4 className="news__post-title heading4">
                  <a
                    href={items.linkUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {items.headline}
                  </a>
                </h4>
                <p className="body-copy d-none d-md-block">{items.excerpt}</p>
                {items.image != null && (
                  <Img
                    className="w-100 d-md-none"
                    fixed={items.image.imageFile.childImageSharp.fixed}
                  />
                )}
                <a
                  className="news__read-more"
                  href={items.linkUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {items.linkText}
                </a>
              </Col>
            </Row>
          </div>
        </Container>
        </RevealBlog>
      ))}
    </article>
  );
};

export default BlogPosts;
