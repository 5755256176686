import React, { useState } from "react";
import { Waypoint } from "react-waypoint"
import { useSpring, animated, config } from "react-spring";
import { Container, Row, Col } from "reactstrap";
import Img from "gatsby-image";

const RevealBlog = (items) => {
  const [on, toggle] = useState(false);
  
  const revealamation = useSpring({
    from: { opacity: 0, transform: "scale(0.9)" },
    to: { opacity: on ? 1 : 0, transform: on ? "scale(1)" : "scale(0.9)" },
    config: config.gentle
  });
  const revealimage = useSpring({
    from: { opacity: 0, transform: "translate3d(0,50px,0)", },
    to: { opacity: on ? 1 : 0, transform: on ? "translate3d(0px, 0px, 0px)" : "translate3d(0,50px,0)", },
    config: config.slow,
    delay: 500
  });
  return (
    <Waypoint 
      bottomOffset='25%'
      onEnter={() => {
        if (!on) toggle(true)
      }}>
      <animated.div style={revealamation}>
        <Container>
          <div className="news__post">
            <Row>
              <Col
                xs={{ size: 12, offset: 1 }}
                sm={{ size: 10, offset: 2 }}
                md={{ size: 6, offset: 1, order: 1 }}
                lg={{ size: 6, offset: 2, order: 1 }}
              >
                <span className="news__post-date">{items.items.date}</span>
              </Col>
            </Row>
            <Row>
              <Col
                md={{ size: 4, offset: 1, order: 2 }}
                lg={{ size: 3, offset: 1, order: 2 }}
              >
                {items.items.image != null && (
                  <animated.div style={revealimage}>
                    <Img
                      className="w-100 d-none d-md-block"
                      fixed={items.items.image.imageFile.childImageSharp.fixed}
                    />
                  </animated.div>
                )}
              </Col>
              <Col
                xs={{ size: 12, offset: 1 }}
                sm={{ size: 10, offset: 2 }}
                md={{ size: 6, offset: 1, order: 1 }}
                lg={{ size: 6, offset: 2, order: 1 }}
              >
                <h4 className="news__post-title heading4">
                  <a
                    href={items.items.linkUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {items.items.headline}
                  </a>
                </h4>
                <p className="body-copy d-none d-md-block">{items.items.excerpt}</p>
                {items.items.image != null && (
                  <animated.div style={revealimage}>
                    <Img
                      className="w-100 d-md-none"
                      fixed={items.items.image.imageFile.childImageSharp.fixed}
                    />
                  </animated.div>
                )}
                <a
                  className="news__read-more"
                  href={items.items.linkUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {items.items.linkText}
                </a>
              </Col>
            </Row>
          </div>
        </Container>
      </animated.div>
    </Waypoint>
  )
}

export default RevealBlog
