import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import BlogPosts from "../components/blog-post";
import { Container, Row, Col } from "reactstrap";
const News = ({ data }) => {
  return (
    <Layout>
      <SEO
        title={data.wpgraphql.page.title}
        description={data.wpgraphql.page.acf_page_meta.metadescription}
      />
      <section className="section__news page type-page status-publish entry">
        <Container>
          <header className="news__header ">
            <Row>
              <Col xs={{ offset: 1 }} sm={{ size: 10 }} lg={{ offset: 2 }}>
                <h1 className="heading1">
                  Ideal <strong>In The News.</strong>
                </h1>
              </Col>
            </Row>
          </header>
        </Container>

        <BlogPosts />
      </section>
    </Layout>
  );
};

export default News;

export const query = graphql`
  query {
    wpgraphql {
      page(id: "cGFnZTo5OQ==") {
        title
        acf_page_meta {
          metadescription
        }
        acf_news {
          newsItem {
            date
            headline
            excerpt
            linkUrl
            linkText
            image {
              sourceUrl
              imageFile {
                childImageSharp {
                  fixed(width: 396, height: 256) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
